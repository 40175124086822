<template>
    <div class="app-container">
        <header>
            <router-link :to="{name: 'ListTurniere'}">
                <button class="btn-unstyled btn-back">
                    <i class="fas fa-chevron-left"></i>
                </button>
            </router-link>
            <h2>{{lang.createTurnier.headline}}</h2>
        </header>
        <CreateTurnierForm/>
        <BottomBarDefault/>
        <md-progress-bar v-if="loadingStatus" md-mode="query"></md-progress-bar>
    </div>
</template>

<script>
import store from '@/store/index'
import CreateTurnierForm from '@/components/Turnier/CreateTurnierForm.vue'
import BottomBarDefault from '@/components/Utils/BottomBarDefault.vue'

export default {
    name: 'ListTurniere',
    components: {
        CreateTurnierForm,
        BottomBarDefault
    },
    computed: {
        lang() {
            return store.getters.getLanguage;
        },
        loadingStatus() {
            return store.state.turnier.loadingStatus;
        },
  },
}
</script>
