<template>
  <div class="turnier-form-container container">
    <transition appear name="fadeRight" mode="out-in">
      <form @submit.prevent="validateForm" id="create-turnier-form">
        <md-steppers :md-active-step.sync="active" md-alternative>
          <md-step id="first" :md-error="firstStepError" :md-done.sync="first">
            <div class="card">
              <md-button @click="showHinweisTurnierDatum()" class="asterisk">*</md-button>
              <md-field class="md-field-icon" :class="getValidationClass('turnierName')">
                <i class="fas fa-beer"></i>
                <label>{{lang.turnierForm.labelTurniername}}</label>
                <md-input @blur="$v.newTurnier.turnierName.$touch()" :name="lang.turnierForm.labelTurniername" v-model="newTurnier.turnierName" :maxlength="$v.newTurnier.turnierName.$params.maxLength.max" type="text"></md-input>
                <md-icon v-if="isFieldInvalid('turnierName')" class="color-invalid">close</md-icon>
                <span class="md-error" v-if="!$v.newTurnier.turnierName.required">{{lang.turnierForm.turnierNameRequired}}</span>
                <span class="md-error" v-else-if="!$v.newTurnier.turnierName.minLength || !$v.newTurnier.turnierName.maxLength ">{{$v.newTurnier.turnierName.$params.minLength.min}} - {{$v.newTurnier.turnierName.$params.maxLength.max}} {{lang.turnierForm.turnierNameMinLength}}</span>
              </md-field>
              <md-datepicker @blur="$v.newTurnier.datum.$touch()" :name="lang.turnierForm.labelDatum" :md-disabled-dates="disabledDates" v-model="newTurnier.datum">
                <label>{{lang.turnierForm.labelDatum}}</label>
              </md-datepicker>
              <md-field class="md-field-icon" :class="getValidationClass('startZeit')">
                <i class="fas fa-beer"></i>
                <label>{{lang.turnierForm.labelStartzeit}}</label>
                <md-input @blur="$v.newTurnier.startZeit.$touch()" :name="lang.turnierForm.labelStartzeit" v-model="newTurnier.startZeit" type="time"></md-input>
                <span class="md-error" v-if="!$v.newTurnier.startZeit.required">{{lang.turnierForm.startZeitRequired}}</span>
              </md-field>
              <md-field class="md-field-icon" :class="getValidationClass('stadt')">
                <i class="fas fa-map-marked-alt"></i>
                <label>{{lang.turnierForm.labelVeranstaltungsort}}</label>
                <md-input @blur="$v.newTurnier.stadt.$touch()" :name="lang.turnierForm.labelVeranstaltungsort" v-model.trim="newTurnier.stadt" type="text"></md-input>
                <span class="md-error" v-if="!$v.newTurnier.stadt.required">{{lang.turnierForm.stadtRequired}}</span>
              </md-field>
              <md-field class="md-field-icon" :class="getValidationClass('passwort')">
                <i class="fas fa-unlock"></i>
                <label>{{lang.turnierForm.labelPasswort}}</label>
                <md-input @blur="$v.newTurnier.passwort.$touch()" :name="lang.turnierForm.labelPasswort" v-model="newTurnier.passwort" type="password" autocomplete="off"></md-input>
                <span class="md-error" v-if="!$v.newTurnier.passwort.required">{{lang.turnierForm.passwortRequired}}</span>
                <span class="md-error" v-else-if="!$v.newTurnier.passwort.minLength">{{$v.newTurnier.passwort.$params.minLength.min}} {{lang.turnierForm.passwortMinLength}}</span>
              </md-field>
              <md-field class="md-field-icon">
                <i class="fas fa-spell-check"></i>
                <label>{{lang.turnierForm.labelBeschreibung}}</label>
                <md-textarea :name="lang.turnierForm.labelBeschreibung" v-model="newTurnier.beschreibung"></md-textarea>
              </md-field>
            </div>
            <md-button class="md-raised md-primary md-filled" @click="setDone('first', 'second')">{{lang.createTurnier.buttonNext}}</md-button>
          </md-step>
          <md-step id="second" :md-error="secondStepError" :md-done.sync="second">
            <div class="card">
              <md-field class="md-field-icon" :class="getValidationClass('anzTische')">
                <i class="fas fa-table-tennis"></i>
                <label>{{lang.turnierForm.labelTische}}</label>
                <md-input @blur="$v.newTurnier.anzTische.$touch()" :name="lang.turnierForm.labelTische" v-model.number="newTurnier.anzTische" step="1" :min="$v.newTurnier.anzTische.$params.minValue.min" type="number"></md-input>
                <md-icon v-if="isFieldInvalid('anzTische')" class="color-invalid">close</md-icon>
                <span class="md-error" v-if="!$v.newTurnier.anzTische.required">{{lang.turnierForm.anzTischeRequired}}</span>
                <span class="md-error" v-else-if="!$v.newTurnier.anzTische.minValue">{{$v.newTurnier.anzTische.$params.minValue.min}} {{lang.turnierForm.anzTischeMinValue}}</span>
              </md-field>
              <md-field class="md-field-icon" :class="getValidationClass('anzTeams')">
                <i class="fas fa-users"></i>
                <label>{{lang.turnierForm.labelTeams}}</label>
                <md-input @blur="$v.newTurnier.anzTeams.$touch()" :name="lang.turnierForm.labelTeams" v-model.number="newTurnier.anzTeams" step="1" :min="$v.newTurnier.anzTeams.$params.between.min" :max="$v.newTurnier.anzTeams.$params.between.max" type="number"></md-input>
                <md-icon v-if="isFieldInvalid('anzTeams')" class="color-invalid">close</md-icon>
                <span class="md-error" v-if="!$v.newTurnier.anzTeams.required">{{lang.turnierForm.anzTeamsRequired}}</span>
                <span class="md-error" v-else-if="!$v.newTurnier.anzTeams.between">{{$v.newTurnier.anzTeams.$params.between.min}} - {{$v.newTurnier.anzTeams.$params.between.max}} {{lang.turnierForm.anzTeamsBetweenValue}}</span>
              </md-field>
              <p class="p-unstyled stepper-text"><strong>{{lang.turnierForm.labelSpielModusEmpfehlung}}</strong></p>
              <p class="p-unstyled stepper-text" :class="spielmodusEmpfehlung === lang.turnierForm.spielModusNull ? 'font-color-lightgray' : ''">
                {{spielmodusEmpfehlung}}
              </p>
              <md-switch v-model="isGruppensystem" :class="isGruppensystem ? 'font-color-secondary' : 'font-color-primary'" > {{isGruppensystem ? lang.turnierForm.spielModusGruppen : lang.turnierForm.spielModusKO}} </md-switch>
              <p v-if="!isGruppensystem">{{lang.turnierForm.labelSpieleKOTeam}}: {{anzSingleSpieleTeam}}</p>
              <p v-if="!isGruppensystem">{{lang.turnierForm.labelSpieleKOAll}}: {{anzSingleSpiele}}</p>
              <md-field v-if="isGruppensystem" class="md-field-icon" :class="getValidationClass('anzGruppen')">
                <i class="fas fa-people-arrows"></i>
                <label>{{lang.turnierForm.labelGruppen}}</label>
                <md-input @blur="$v.newTurnier.anzGruppen.$touch()" :name="lang.turnierForm.labelGruppen" v-model.number="newTurnier.anzGruppen" step="1" :min="$v.newTurnier.anzGruppen.$params.minValue.min" type="number"></md-input>
                <md-icon v-if="isFieldInvalid('anzGruppen')" class="color-invalid">close</md-icon>
                <span class="md-helper-text">
                {{lang.turnierForm.labelEmpfehlungGruppen}} {{anzSpielerProGruppeEmpfehlung}} <br>
                {{lang.turnierForm.labelSpielerProGruppe}} {{anzSpielerProGruppe}} <br>
                {{lang.turnierForm.labelSpieleGruppenphase}} {{anzSpieleProGruppe}}</span>
                <span class="md-error" v-if="!$v.newTurnier.anzGruppen.required">{{lang.turnierForm.anzGruppenRequired}}</span>
                <span class="md-error" v-else-if="!$v.newTurnier.anzGruppen.minValue">{{$v.newTurnier.anzGruppen.$params.minValue.min}} {{lang.turnierForm.anzGruppenMinValue}}</span>
              </md-field>
              <md-checkbox v-if="isGruppensystem" :name="lang.turnierForm.labelGruppenPhaseZweiterWeiter" v-model="newTurnier.gruppenPhaseZweiterWeiter">{{lang.turnierForm.labelGruppenPhaseZweiterWeiter}}</md-checkbox>
            </div>
            <md-button class="md-raised md-primary md-filled" @click="setDone('second', 'third')">{{lang.createTurnier.buttonNext}}</md-button>
          </md-step>
          <md-step class="spielzeiten-step" id="third" :md-error="thirdStepError" :md-done.sync="third">
            <p class="p-unstyled stepper-text">{{lang.turnierForm.hinweisTextZeit}}</p>
            <div>
              <p class="p-unstyled stepper-text">{{lang.turnierForm.spielZeit}}: {{gesamtSpielzeit}}<md-button @click="showHinweisSpielzeit()" class="asterisk">*</md-button></p>
            </div>
            <div class="card">
              <md-field class="md-field-icon" :class="getValidationClass('pausenZeiten')">
                <i class="fas fa-clock"></i>
                <label>{{lang.turnierForm.labelPausenzeiten}}</label>
                <md-input @blur="$v.newTurnier.pausenZeiten.$touch()" :name="lang.turnierForm.labelPausenzeiten" v-model.number="newTurnier.pausenZeiten" step="1" :min="$v.newTurnier.pausenZeiten.$params.minValue.min" type="number"></md-input>
                <md-icon v-if="isFieldInvalid('pausenZeiten')" class="color-invalid">close</md-icon>
                <span class="md-helper-text">{{lang.turnierForm.labelZeitEinheit}}</span>
                <span class="md-error" v-if="!$v.newTurnier.pausenZeiten.required">{{lang.turnierForm.pausenZeitenRequired}}</span>
                <span class="md-error" v-else-if="!$v.newTurnier.pausenZeiten.minValue">{{$v.newTurnier.pausenZeiten.$params.minValue.min}} {{lang.turnierForm.pausenZeitenMinValue}}</span>
              </md-field>
              <div v-if="!isGruppensystem">
                <md-field class="md-field-icon" :class="getValidationClass('spielzeiten.spielzeitEinzel.spielZeit')">
                  <i class="fas fa-clock"></i>
                  <label>{{lang.turnierForm.labelSpielZeitKO}}</label>
                  <md-input @blur="$v.newTurnier.spielzeiten.spielzeitEinzel.spielZeit.$touch()" :name="lang.turnierForm.labelSpielZeitKO" v-model.number="newTurnier.spielzeiten.spielzeitEinzel.spielZeit" step="1" :min="$v.newTurnier.spielzeiten.spielzeitEinzel.spielZeit.$params.minValue.min" type="number"></md-input>
                  <md-icon v-if="isFieldInvalid('spielzeiten.spielzeitEinzel.spielZeit')" class="color-invalid">close</md-icon>
                  <span class="md-helper-text">{{lang.turnierForm.labelZeitEinheit}}</span>
                  <span class="md-error" v-if="!$v.newTurnier.spielzeiten.spielzeitEinzel.spielZeit.required">{{lang.turnierForm.spielzeitRequired}}</span>
                  <span class="md-error" v-else-if="!$v.newTurnier.spielzeiten.spielzeitEinzel.spielZeit.minValue">{{$v.newTurnier.spielzeiten.spielzeitEinzel.spielZeit.$params.minValue.min}} {{lang.turnierForm.spielzeitMinValue}}</span>
                </md-field>
              </div>
              <div v-if="isGruppensystem">
                <md-field class="md-field-icon" :class="getValidationClass('spielzeiten.spielzeitGruppenphase.spielZeit')">
                  <i class="fas fa-clock"></i>
                  <label>{{lang.turnierForm.labelSpielZeitGruppenphase}}</label>
                  <md-input @blur="$v.newTurnier.spielzeiten.spielzeitGruppenphase.spielZeit.$touch()" :name="lang.turnierForm.labelSpielZeitGruppenphase" v-model.number="newTurnier.spielzeiten.spielzeitGruppenphase.spielZeit" step="1" :min="$v.newTurnier.spielzeiten.spielzeitGruppenphase.spielZeit.$params.minValue.min" type="number"></md-input>
                  <md-icon v-if="isFieldInvalid('spielzeiten.spielzeitGruppenphase.spielZeit')" class="color-invalid">close</md-icon>
                  <span class="md-helper-text">{{lang.turnierForm.labelZeitEinheit}}</span>
                  <span class="md-error" v-if="!$v.newTurnier.spielzeiten.spielzeitGruppenphase.spielZeit.required">{{lang.turnierForm.spielzeitRequired}}</span>
                  <span class="md-error" v-else-if="!$v.newTurnier.spielzeiten.spielzeitGruppenphase.spielZeit.minValue">{{$v.newTurnier.spielzeiten.spielzeitGruppenphase.spielZeit.$params.minValue.min}} {{lang.turnierForm.spielzeitMinValue}}</span>
                </md-field>
                <md-field class="md-field-icon" :class="getValidationClass('spielzeiten.spielzeit512.spielZeit')" v-if="anzTeamsFirstFinalGame >= 512">
                  <i class="fas fa-clock"></i>
                  <label>{{lang.turnierForm.labelSpielZeit256}}</label>
                  <md-input @blur="$v.newTurnier.spielzeiten.spielzeit512.spielZeit.$touch()" :name="lang.turnierForm.labelSpielZeit256" v-model.number="newTurnier.spielzeiten.spielzeit512.spielZeit" step="1" :min="$v.newTurnier.spielzeiten.spielzeit512.spielZeit.$params.minValue.min" type="number"></md-input>
                  <md-icon v-if="isFieldInvalid('spielzeiten.spielzeit512.spielZeit')" class="color-invalid">close</md-icon>
                  <span class="md-helper-text">{{lang.turnierForm.labelZeitEinheit}}</span>
                  <span class="md-error" v-if="!$v.newTurnier.spielzeiten.spielzeit512.spielZeit.required">{{lang.turnierForm.spielzeitRequired}}</span>
                  <span class="md-error" v-else-if="!$v.newTurnier.spielzeiten.spielzeit512.spielZeit.minValue">{{$v.newTurnier.spielzeiten.spielzeit512.spielZeit.$params.minValue.min}} {{lang.turnierForm.spielzeitMinValue}}</span>
                </md-field>
                <md-field class="md-field-icon" :class="getValidationClass('spielzeiten.spielzeit256.spielZeit')" v-if="anzTeamsFirstFinalGame >= 256">
                  <i class="fas fa-clock"></i>
                  <label>{{lang.turnierForm.labelSpielZeit128}}</label>
                  <md-input @blur="$v.newTurnier.spielzeiten.spielzeit256.spielZeit.$touch()" :name="lang.turnierForm.labelSpielZeit128" v-model.number="newTurnier.spielzeiten.spielzeit256.spielZeit" step="1" :min="$v.newTurnier.spielzeiten.spielzeit256.spielZeit.$params.minValue.min" type="number"></md-input>
                  <md-icon v-if="isFieldInvalid('spielzeiten.spielzeit256.spielZeit')" class="color-invalid">close</md-icon>
                  <span class="md-helper-text">{{lang.turnierForm.labelZeitEinheit}}</span>
                  <span class="md-error" v-if="!$v.newTurnier.spielzeiten.spielzeit256.spielZeit.required">{{lang.turnierForm.spielzeitRequired}}</span>
                  <span class="md-error" v-else-if="!$v.newTurnier.spielzeiten.spielzeit256.spielZeit.minValue">{{$v.newTurnier.spielzeiten.spielzeit256.spielZeit.$params.minValue.min}} {{lang.turnierForm.spielzeitMinValue}}</span>
                </md-field>
                <md-field class="md-field-icon" :class="getValidationClass('spielzeiten.spielzeit128.spielZeit')" v-if="anzTeamsFirstFinalGame >= 128">
                  <i class="fas fa-clock"></i>
                  <label>{{lang.turnierForm.labelSpielZeit64}}</label>
                  <md-input @blur="$v.newTurnier.spielzeiten.spielzeit128.spielZeit.$touch()" :name="lang.turnierForm.labelSpielZeit64" v-model.number="newTurnier.spielzeiten.spielzeit128.spielZeit" step="1" :min="$v.newTurnier.spielzeiten.spielzeit128.spielZeit.$params.minValue.min" type="number"></md-input>
                  <md-icon v-if="isFieldInvalid('spielzeiten.spielzeit128.spielZeit')" class="color-invalid">close</md-icon>
                  <span class="md-helper-text">{{lang.turnierForm.labelZeitEinheit}}</span>
                  <span class="md-error" v-if="!$v.newTurnier.spielzeiten.spielzeit128.spielZeit.required">{{lang.turnierForm.spielzeitRequired}}</span>
                  <span class="md-error" v-else-if="!$v.newTurnier.spielzeiten.spielzeit128.spielZeit.minValue">{{$v.newTurnier.spielzeiten.spielzeit128.spielZeit.$params.minValue.min}} {{lang.turnierForm.spielzeitMinValue}}</span>
                </md-field>
                <md-field class="md-field-icon" :class="getValidationClass('spielzeiten.spielzeit64.spielZeit')" v-if="anzTeamsFirstFinalGame >= 64">
                  <i class="fas fa-clock"></i>
                  <label>{{lang.turnierForm.labelSpielZeit32}}</label>
                  <md-input @blur="$v.newTurnier.spielzeiten.spielzeit64.spielZeit.$touch()" :name="lang.turnierForm.labelSpielZeit32" v-model.number="newTurnier.spielzeiten.spielzeit64.spielZeit" step="1" :min="$v.newTurnier.spielzeiten.spielzeit64.spielZeit.$params.minValue.min" type="number"></md-input>
                  <md-icon v-if="isFieldInvalid('spielzeiten.spielzeit64.spielZeit')" class="color-invalid">close</md-icon>
                  <span class="md-helper-text">{{lang.turnierForm.labelZeitEinheit}}</span>
                  <span class="md-error" v-if="!$v.newTurnier.spielzeiten.spielzeit64.spielZeit.required">{{lang.turnierForm.spielzeitRequired}}</span>
                  <span class="md-error" v-else-if="!$v.newTurnier.spielzeiten.spielzeit64.spielZeit.minValue">{{$v.newTurnier.spielzeiten.spielzeit64.spielZeit.$params.minValue.min}} {{lang.turnierForm.spielzeitMinValue}}</span>
                </md-field>
                <md-field class="md-field-icon" :class="getValidationClass('spielzeiten.spielzeit32.spielZeit')" v-if="anzTeamsFirstFinalGame >= 32">
                  <i class="fas fa-clock"></i>
                  <label>{{lang.turnierForm.labelSpielZeit16}}</label>
                  <md-input @blur="$v.newTurnier.spielzeiten.spielzeit32.spielZeit.$touch()" :name="lang.turnierForm.labelSpielZeit16" v-model.number="newTurnier.spielzeiten.spielzeit32.spielZeit" step="1" :min="$v.newTurnier.spielzeiten.spielzeit32.spielZeit.$params.minValue.min" type="number"></md-input>
                  <md-icon v-if="isFieldInvalid('spielzeiten.spielzeit32.spielZeit')" class="color-invalid">close</md-icon>
                  <span class="md-helper-text">{{lang.turnierForm.labelZeitEinheit}}</span>
                  <span class="md-error" v-if="!$v.newTurnier.spielzeiten.spielzeit32.spielZeit.required">{{lang.turnierForm.spielzeitRequired}}</span>
                  <span class="md-error" v-else-if="!$v.newTurnier.spielzeiten.spielzeit32.spielZeit.minValue">{{$v.newTurnier.spielzeiten.spielzeit32.spielZeit.$params.minValue.min}} {{lang.turnierForm.spielzeitMinValue}}</span>
                </md-field>
                <md-field class="md-field-icon" :class="getValidationClass('spielzeiten.spielzeit16.spielZeit')" v-if="anzTeamsFirstFinalGame >= 16">
                  <i class="fas fa-clock"></i>
                  <label>{{lang.turnierForm.labelSpielZeit8}}</label>
                  <md-input @blur="$v.newTurnier.spielzeiten.spielzeit16.spielZeit.$touch()" :name="lang.turnierForm.labelSpielZeit8" v-model.number="newTurnier.spielzeiten.spielzeit16.spielZeit" step="1" :min="$v.newTurnier.spielzeiten.spielzeit16.spielZeit.$params.minValue.min" type="number"></md-input>
                  <md-icon v-if="isFieldInvalid('spielzeiten.spielzeit16.spielZeit')" class="color-invalid">close</md-icon>
                  <span class="md-helper-text">{{lang.turnierForm.labelZeitEinheit}}</span>
                  <span class="md-error" v-if="!$v.newTurnier.spielzeiten.spielzeit16.spielZeit.required">{{lang.turnierForm.spielzeitRequired}}</span>
                  <span class="md-error" v-else-if="!$v.newTurnier.spielzeiten.spielzeit16.spielZeit.minValue">{{$v.newTurnier.spielzeiten.spielzeit16.spielZeit.$params.minValue.min}} {{lang.turnierForm.spielzeitMinValue}}</span>
                </md-field>
                <md-field class="md-field-icon" :class="getValidationClass('spielzeiten.spielzeit8.spielZeit')" v-if="anzTeamsFirstFinalGame >= 8">
                  <i class="fas fa-clock"></i>
                  <label>{{lang.turnierForm.labelSpielZeit4}}</label>
                  <md-input @blur="$v.newTurnier.spielzeiten.spielzeit8.spielZeit.$touch()" :name="lang.turnierForm.labelSpielZeit4" v-model.number="newTurnier.spielzeiten.spielzeit8.spielZeit" step="1" :min="$v.newTurnier.spielzeiten.spielzeit8.spielZeit.$params.minValue.min" type="number"></md-input>
                  <md-icon v-if="isFieldInvalid('spielzeiten.spielzeit8.spielZeit')" class="color-invalid">close</md-icon>
                  <span class="md-helper-text">{{lang.turnierForm.labelZeitEinheit}}</span>
                  <span class="md-error" v-if="!$v.newTurnier.spielzeiten.spielzeit8.spielZeit.required">{{lang.turnierForm.spielzeitRequired}}</span>
                  <span class="md-error" v-else-if="!$v.newTurnier.spielzeiten.spielzeit8.spielZeit.minValue">{{$v.newTurnier.spielzeiten.spielzeit8.spielZeit.$params.minValue.min}} {{lang.turnierForm.spielzeitMinValue}}</span>
                </md-field>
                <md-field class="md-field-icon" :class="getValidationClass('spielzeiten.spielzeit4.spielZeit')" v-if="anzTeamsFirstFinalGame >= 4">
                  <i class="fas fa-clock"></i>
                  <label>{{lang.turnierForm.labelSpielZeit2}}</label>
                  <md-input @blur="$v.newTurnier.spielzeiten.spielzeit4.spielZeit.$touch()" :name="lang.turnierForm.labelSpielZeit2" v-model.number="newTurnier.spielzeiten.spielzeit4.spielZeit" step="1" :min="$v.newTurnier.spielzeiten.spielzeit4.spielZeit.$params.minValue.min" type="number"></md-input>
                  <md-icon v-if="isFieldInvalid('spielzeiten.spielzeit4.spielZeit')" class="color-invalid">close</md-icon>
                  <span class="md-helper-text">{{lang.turnierForm.labelZeitEinheit}}</span>
                  <span class="md-error" v-if="!$v.newTurnier.spielzeiten.spielzeit4.spielZeit.required">{{lang.turnierForm.spielzeitRequired}}</span>
                  <span class="md-error" v-else-if="!$v.newTurnier.spielzeiten.spielzeit4.spielZeit.minValue">{{$v.newTurnier.spielzeiten.spielzeit4.spielZeit.$params.minValue.min}} {{lang.turnierForm.spielzeitMinValue}}</span>
                </md-field>
                <md-field class="md-field-icon" :class="getValidationClass('spielzeiten.spielzeitPlatz3.spielZeit')" v-if="anzTeamsFirstFinalGame >= 4">
                  <i class="fas fa-clock"></i>
                  <label>{{lang.turnierForm.labelSpielZeitPlatz3}}</label>
                  <md-input @blur="$v.newTurnier.spielzeiten.spielzeitPlatz3.spielZeit.$touch()" :name="lang.turnierForm.labelSpielZeitPlatz3" v-model.number="newTurnier.spielzeiten.spielzeitPlatz3.spielZeit" step="1" :min="$v.newTurnier.spielzeiten.spielzeitPlatz3.spielZeit.$params.minValue.min" type="number"></md-input>
                  <md-icon v-if="isFieldInvalid('spielzeiten.spielzeitPlatz3.spielZeit')" class="color-invalid">close</md-icon>
                  <span class="md-helper-text">{{lang.turnierForm.labelZeitEinheit}}</span>
                  <span class="md-error" v-if="!$v.newTurnier.spielzeiten.spielzeitPlatz3.spielZeit.required">{{lang.turnierForm.spielzeitRequired}}</span>
                  <span class="md-error" v-else-if="!$v.newTurnier.spielzeiten.spielzeitPlatz3.spielZeit.minValue">{{$v.newTurnier.spielzeiten.spielzeitPlatz3.spielZeit.$params.minValue.min}} {{lang.turnierForm.spielzeitMinValue}}</span>
                </md-field>
                <md-field class="md-field-icon" :class="getValidationClass('spielzeiten.spielzeitFinale.spielZeit')" v-if="anzTeamsFirstFinalGame >= 2">
                  <i class="fas fa-clock"></i>
                  <label>{{lang.turnierForm.labelSpielZeitFinale}}</label>
                  <md-input @blur="$v.newTurnier.spielzeiten.spielzeitFinale.spielZeit.$touch()" :name="lang.turnierForm.labelSpielZeitFinale" v-model.number="newTurnier.spielzeiten.spielzeitFinale.spielZeit" step="1" :min="$v.newTurnier.spielzeiten.spielzeitFinale.spielZeit.$params.minValue.min" type="number"></md-input>
                  <md-icon v-if="isFieldInvalid('spielzeiten.spielzeitFinale.spielZeit')" class="color-invalid">close</md-icon>
                  <span class="md-helper-text">{{lang.turnierForm.labelZeitEinheit}}</span>
                  <span class="md-error" v-if="!$v.newTurnier.spielzeiten.spielzeitFinale.spielZeit.required">{{lang.turnierForm.spielzeitRequired}}</span>
                  <span class="md-error" v-else-if="!$v.newTurnier.spielzeiten.spielzeitFinale.spielZeit.minValue">{{$v.newTurnier.spielzeiten.spielzeitFinale.spielZeit.$params.minValue.min}} {{lang.turnierForm.spielzeitMinValue}}</span>
                </md-field>
              </div>
            </div>
            <md-button type="submit" class="md-raised md-primary md-filled" @click="setDone('third')">{{lang.createTurnier.buttonSubmit}}</md-button>
          </md-step>
        </md-steppers>
      </form>
    </transition>
    <InformationModal/>
    <SubmitModal/>
  </div>
</template>

<script>
import store from '@/store/index'
import SubmitModal from '../Utils/SubmitModal'
import InformationModal from '../Utils/InformationModal'
import router from '@/router/index'
import {required, minLength, minValue, between, maxLength} from 'vuelidate/lib/validators'
import { VueOfflineMixin } from 'vue-offline'

export default {
  name: 'CreateTurnierForm',
  mixins: [VueOfflineMixin],
  components: {
    SubmitModal,
    InformationModal
  },
  data() {
    return {
      newTurnier: {
        turnierName: "",
        beschreibung: "",
        datum: new Date(),
        startZeit: "18:00",
        stadt: "",
        passwort: "",
        anzTeams: null,
        anzTische: null,
        pausenZeiten: 8,
        spielmodus: store.getters.getSpielModus.GRUPPEN_SYSTEM.name,
        anzGruppen: null,
        gruppenPhaseZweiterWeiter: true,
        spielzeiten: {
          spielzeitEinzel: {
            spielArt: store.getters.getSpielArt.EINZELSPIEL,
            spielZeit: 12
          },
          spielzeitGruppenphase: {
            spielArt: store.getters.getSpielArt.GRUPPENSPIEL,
            spielZeit: 12
          },
          spielzeit512: {
            spielArt: store.getters.getSpielArt.FINALE_512,
            spielZeit: 12
          },
          spielzeit256: {
            spielArt: store.getters.getSpielArt.FINALE_256,
            spielZeit: 12
          },
          spielzeit128: {
            spielArt: store.getters.getSpielArt.FINALE_128,
            spielZeit: 12
          },
          spielzeit64: {
            spielArt: store.getters.getSpielArt.FINALE_64,
            spielZeit: 12
          },
          spielzeit32: {
            spielArt: store.getters.getSpielArt.FINALE_32,
            spielZeit: 12
          },
          spielzeit16: {
            spielArt: store.getters.getSpielArt.FINALE_16,
            spielZeit: 12
          },
          spielzeit8: {
            spielArt: store.getters.getSpielArt.FINALE_8,
            spielZeit: 12
          },
          spielzeit4: {
            spielArt: store.getters.getSpielArt.FINALE_4,
            spielZeit: 12
          },
          spielzeitPlatz3: {
            spielArt: store.getters.getSpielArt.FINALE_PLATZ3,
            spielZeit: 0
          },
          spielzeitFinale: {
            spielArt: store.getters.getSpielArt.FINALE,
            spielZeit: 0
          },
        }
      },
      active: 'first',
      first: false,
      second: false,
      third: false,
      firstStepError: null,
      secondStepError: null,
      thirdStepError: null,
      isGruppensystem: true,
      disabledDates: date => {
        let today = new Date();
        today.setDate(today.getDate()-1)
        return date < today;
      }
    }
  },
  validations() {
    if (this.isGruppensystem) {
      return {
        newTurnier: {
          turnierName: {
            required,
            minLength: minLength(2),
            maxLength: maxLength(28)
          },
          startZeit: {
            required
          },
          stadt: {
            required
          },
          passwort: {
            required,
            minLength: minLength(2)
          },
          anzTeams: {
            required,
            between: between(4, 1024)
          },
          anzGruppen: {
            required,
            minValue: minValue(2)
          },
          anzTische: {
            required,
            minValue: minValue(1)
          },
          pausenZeiten: {
            required,
            minValue: minValue(0)
          },
          spielzeiten: {
            spielzeitGruppenphase: {
              spielZeit: {
                required,
                minValue: minValue(0)
              }
            },
            spielzeit512: {
              spielZeit: {
                required,
                minValue: minValue(0)
              }
            },
            spielzeit256: {
              spielZeit: {
                required,
                minValue: minValue(0)
              }
            },
            spielzeit128: {
              spielZeit: {
                required,
                minValue: minValue(0)
              }
            },
            spielzeit64: {
              spielZeit: {
                required,
                minValue: minValue(0)
              }
            },
            spielzeit32: {
              spielZeit: {
                required,
                minValue: minValue(0)
              }
            },
            spielzeit16: {
              spielZeit: {
                required,
                minValue: minValue(0)
              }
            },
            spielzeit8: {
              spielZeit: {
                required,
                minValue: minValue(0)
              }
            },
            spielzeit4: {
              spielZeit: {
                required,
                minValue: minValue(0)
              }
            },
            spielzeitPlatz3: {
              spielZeit: {
                required,
                minValue: minValue(0)
              }
            },
            spielzeitFinale: {
              spielZeit: {
                required,
                minValue: minValue(0)
              }
            }
          }
        }
      }
    } 
    else {
      return {
        newTurnier: {
          turnierName: {
            required,
            minLength: minLength(2),
            maxLength: maxLength(28)
          },
          startZeit: {
            required
          },
          stadt: {
            required
          },
          passwort: {
            required,
            minLength: minLength(2)
          },
          anzTeams: {
            required,
            between: between(3, 1024)
          },
          anzTische: {
            required,
            minValue: minValue(1)
          },
          pausenZeiten: {
            required,
            minValue: minValue(0)
          },
          spielzeiten: {
            spielzeitEinzel: {
              spielZeit: {
                required,
                minValue: minValue(0)
              }
            }
          }
        }
      }
    }
  },
  watch: {
    // whenever question changes, this function will run
    isGruppensystem: function(newVal, oldVal) {
      if (!newVal) {
        this.newTurnier.anzGruppen = null;
      }
    }
  },
  methods: {
    validateForm() {
      this.$v.newTurnier.$touch();

      if (!this.$v.newTurnier.$invalid) {
        this.addTurnier();
      }
      else {
        this.showDialogActive(false, this.lang.error.formValidationHeadline, this.lang.error.formValidationMessage);
      }
    },
    getValidationClass(fieldName) {
      return {
        'md-invalid': this.isFieldInvalid(fieldName)
      }
    },
    isFieldInvalid(fieldName) {
      const identifier = fieldName.split(".");
      const field = identifier.length == 3 ? this.$v.newTurnier[identifier[0]][identifier[1]][identifier[2]] : this.$v.newTurnier[identifier[0]];
      if (field) {
        return field.$invalid && field.$dirty;
      }
    },
    async addTurnier() {
      if (this.isOffline) {
        this.showDialogActive(false, this.lang.modal.errorHeadline, this.lang.offline.offlineErrorMessage);
      } else {
        let spielArtTurnier = {}
        spielArtTurnier = Object.values(this.newTurnier.spielzeiten)
          .map(spielzeiten => {
            return {
                spielArt: {
                  spielArtEnum: spielzeiten.spielArt.name
                },
                spielZeit: spielzeiten.spielZeit
              }
          });
          
        let turnier = {...this.newTurnier};
        delete turnier.spielzeiten;
        turnier.spielArtTurnier = spielArtTurnier;
        turnier.spielmodus = this.isGruppensystem ? store.getters.getSpielModus.GRUPPEN_SYSTEM.name : store.getters.getSpielModus.KO_SYSTEM.name;
        await store.dispatch('postTurnier', turnier)
          .then(result => {
            if (result.data != null) {
              this.showDialogActive(true, this.lang.createTurnier.successHeadline, "");
              store.dispatch('getSpielerData'); //spieler has new gründer role
              setTimeout(() => {
                store.commit("SET_SHOW_SUBMIT_MODAL_STATUS", false)
                router.push({name: 'TurnierDetail', params: {turnierID: result.data.turnierID}});
              }, store.state.submitModalSuccessSeconds);
            } else {
              this.showDialogActive(false, this.lang.modal.errorHeadline, store.state.turnier.apiReponseErrorMessage)
            }
          })
          .catch(error => {
            this.showDialogActive(false, this.lang.modal.errorHeadline, store.state.turnier.apiReponseErrorMessage)
          });
      }
    },
    showDialogActive(formSuccess, formSuccessHeadline, formSuccessMessage) {
      store.commit("SET_SHOW_SUBMIT_MODAL", {
        show: true,
        success: formSuccess,
        headline: formSuccessHeadline,
        message: formSuccessMessage
      });
    },
    setDone(id, index) {
      this[id] = true
      this.secondStepError = null
      if (index) {
        this.active = index
      }
    },
    binomial(n, k) {
      if ((typeof n !== 'number') || (typeof k !== 'number')) {
        return false; 
      }
      let coeff = 1;
      for (var x = n-k+1; x <= n; x++) {
        coeff *= x; 
      }
      for (x = 1; x <= k; x++) {
        coeff /= x;
      } 
      return coeff;
    },
    showHinweisSpielzeit() {
      store.commit("SET_SHOW_INFORMATION_DIALOG", {
        show: true,
        headline: this.lang.turnierForm.spielZeitenHinweisHeadline,
        message: (this.isGruppensystem ? this.lang.turnierForm.spielZeitenHinweisGruppen: this.lang.turnierForm.spielZeitenHinweisKO) + ' ' + this.lang.turnierForm.spielZeitenHinweis
      });
    },
    showHinweisTurnierDatum() {
      store.commit("SET_SHOW_INFORMATION_DIALOG", {
        show: true,
        headline: this.lang.turnierForm.turnierDatumHinweisHeadline,
        message: this.lang.turnierForm.turnierDatumHinweis
      });
    }
  },
  computed: {
    lang() {
      return store.getters.getLanguage;
    },
    spielmodusEmpfehlung() {
      if (this.newTurnier.anzTeams < 3 || this.newTurnier.anzTeams == null) {
        return this.lang.turnierForm.spielModusNull;
      } else {
        return this.newTurnier.anzTeams > 6 ? this.lang.turnierForm.spielModusGruppen : this.lang.turnierForm.spielModusKO;
      }
    },
    anzSingleSpiele() {
      if (this.newTurnier.anzTeams < 2 || this.newTurnier.anzTeams == null) {
        return 0;
      } else {
        return this.binomial(this.newTurnier.anzTeams, 2);
      }
    },
    anzSingleSpieleTeam() {
      if (this.newTurnier.anzTeams < 2 || this.newTurnier.anzTeams == null) {
        return 0;
      } else {
        return this.newTurnier.anzTeams-1;
      }
    },
    anzTeamsFirstFinalGame() {
      if (this.newTurnier.anzGruppen == null || this.newTurnier.anzGruppen < 2) {
        return 0;
      }
      let anzteamsFinalSpiel1 = this.newTurnier.anzGruppen * (this.newTurnier.gruppenPhaseZweiterWeiter ? 2 : 1);
      if (anzteamsFinalSpiel1 < 2) {
        return 0;
      }
      let exponent = 0;
      do {
          if (anzteamsFinalSpiel1 > Math.pow(2, exponent) && anzteamsFinalSpiel1 < Math.pow(2, exponent + 1)) {
              anzteamsFinalSpiel1 = Math.pow(2, exponent + 1);
          }
          exponent += 1;
      } while (anzteamsFinalSpiel1 != Math.pow(2, exponent))

      return anzteamsFinalSpiel1;
    },
    gesamtSpielzeit() {
      const defaultSpielZeit = 15;
      let spielZeitGesamt = 0;
      if (this.isGruppensystem) {
        const anzTeamsWeiter = this.anzTeamsFirstFinalGame;
        if (anzTeamsWeiter < 4 || this.anzSpieleProGruppe == 0 || this.newTurnier.anzGruppen == 0 || this.newTurnier.anzTische == 0) {
          return "";
        }
        const exponent = (Math.log(anzTeamsWeiter) / Math.log(2));
        let spielzeitFinalPhase = this.newTurnier.spielzeiten.spielzeitPlatz3.spielZeit == 0 ? defaultSpielZeit+this.newTurnier.pausenZeiten : this.newTurnier.spielzeiten.spielzeitPlatz3.spielZeit+this.newTurnier.pausenZeiten;
        for (let i = exponent; i > 0; i--) {
          const anzTeamsFinalSpielArt = Math.pow(2, i);
          const anzSpieleFinalSpielArt = anzTeamsFinalSpielArt/2;
          const anzTische = this.newTurnier.anzTische > anzSpieleFinalSpielArt ? anzSpieleFinalSpielArt : this.newTurnier.anzTische;
          const spielArt = Object.values(this.newTurnier.spielzeiten).find(s => s.spielArt.countTeams == anzTeamsFinalSpielArt);
          const spielArtSpielZeit = spielArt == undefined ? 15 : spielArt.spielZeit;
          spielArtSpielZeit = spielArtSpielZeit == 0 ? defaultSpielZeit : spielArtSpielZeit;
          anzSpieleFinalSpielArt = Math.floor(anzSpieleFinalSpielArt/anzTische) + (anzSpieleFinalSpielArt%anzTische)
          spielzeitFinalPhase += (anzSpieleFinalSpielArt*(spielArtSpielZeit+this.newTurnier.pausenZeiten));
        }
        //passt nicht zu 100%, wenn Teamanzahl über die Gruppen ungleich
        const anzTische = this.newTurnier.anzTische > this.newTurnier.anzGruppen ? this.newTurnier.anzGruppen : this.newTurnier.anzTische;
        const tischeProGruppe = Math.floor(this.newTurnier.anzGruppen/anzTische) + (this.newTurnier.anzGruppen%anzTische)
        const anzSpieleGruppenPhase = this.anzSpieleProGruppe * tischeProGruppe;
        const spielZeitGruppenPhase = anzSpieleGruppenPhase * (this.newTurnier.spielzeiten.spielzeitGruppenphase.spielZeit+this.newTurnier.pausenZeiten);
        spielZeitGesamt = spielZeitGruppenPhase + spielzeitFinalPhase;
      } else {
        if (this.anzSingleSpiele == 0 && this.newTurnier.anzTische < 1) {
          return "";
        }
        const spielZeitEinzel = this.newTurnier.spielzeiten.spielzeitEinzel.spielZeit == 0 ? defaultSpielZeit : this.newTurnier.spielzeiten.spielzeitEinzel.spielZeit;
        const anzTische = this.newTurnier.anzTische > 3 ? 2 : this.newTurnier.anzTische;
        spielZeitGesamt = (this.anzSingleSpiele * (spielZeitEinzel + this.newTurnier.pausenZeiten))/anzTische;
      }
      const spielZeitGesamtStunden = Math.floor(spielZeitGesamt / 60);
      const spielZeitGesamtMinuten = spielZeitGesamt % 60;
      return (spielZeitGesamtStunden > 0 ? `${spielZeitGesamtStunden}h` : '') + ' ' + (spielZeitGesamtMinuten > 0 ? `${spielZeitGesamtMinuten}min`: '');
    },
    anzSpielerProGruppeEmpfehlung() {
      if (this.newTurnier.anzTeams < 4 || this.newTurnier.anzTeams == null) {
        return 0;
      } else {
        return Math.round(this.newTurnier.anzTeams/4);
      }
    },
    anzSpielerProGruppe() {
      if (this.newTurnier.anzTeams == 0 || this.newTurnier.anzTeams == null || this.anzSpielerProGruppeEmpfehlung == 0) {
        return 0;
      } else if (this.newTurnier.anzGruppen == 0 || this.newTurnier.anzGruppen == null) {
        return Math.round(this.newTurnier.anzTeams/this.anzSpielerProGruppeEmpfehlung);
      }
      else {
        return Math.round(this.newTurnier.anzTeams/this.newTurnier.anzGruppen);
      }
    },
    anzSpieleProGruppe() {
      if (this.anzSpielerProGruppe == 0) {
        return 0;
      } else {
        return this.binomial(this.anzSpielerProGruppe, 2);
      }
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
