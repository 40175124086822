import { render, staticRenderFns } from "./CreateTurnierForm.vue?vue&type=template&id=6fa4725f&scoped=true&"
import script from "./CreateTurnierForm.vue?vue&type=script&lang=js&"
export * from "./CreateTurnierForm.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6fa4725f",
  null
  
)

export default component.exports